import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { ToastContainer } from 'react-toastify';

// css
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./App.css";
import "../src/assets/css/theme.css";

import { app_url } from "./config";
import ComingSoon from "./components/ComingSoon";

function App() {
  
  return (
    <div className="">
      <Router>
        {/* <ToastContainer theme='colored' /> */}

        <Routes>
          <Route path={`${app_url}`} Component={ComingSoon} />
        </Routes>

      </Router>

    </div>
  );
}

export default App;
