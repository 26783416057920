import React from 'react'
import logo from "../assets/Images/GreenFactory_Logo.jpg"
import collageImage from "../assets/Images/Greenfactory_collage.jpg"
import { FaEnvelope, FaPhone } from 'react-icons/fa';

const ComingSoon = () => {
    return (
        <div className="coming-soon-container">
            <div className="logo-container">
                <img src={logo} alt="Company Logo" className="company-logo" />
            </div>
            <div className="message-container">
                <h1 className="heading fs-4 ">Hello! <br /> <br />  We're  </h1>
                <h2 className="heading mt-2 fw-bold">  COMING SOON! </h2>
                {/* <p className="subheading">Something amazing is on the way...</p> */}
            </div>
            <div className="mt-4 d-md-flex">
                <div className="contact-info rounded bg-white text-dark mx-3 px-md-4 px-3 py-1 shadow-sm">
                    <FaEnvelope className='text-yellow me-2' />
                    <a href="mailto:hello@greenfactory.world"> hello@greenfactory.world</a> </div>
                <div className="contact-info rounded bg-white text-dark mx-3 px-md-4 px-3 py-1 shadow-sm">
                    <FaPhone className='text-yellow me-2' />
                    <a href="tel:+91 6262979701/07">+91 6262979701/07</a>
                </div>
            </div>
            <div className='mt-5 pt-md-4 px-md-5'>
                <img src={collageImage} alt="" className='img-fluid' />
            </div>
        </div>
    );
}

export default ComingSoon